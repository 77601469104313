<template>
    <div id="articles">
        <mainheader></mainheader>
        <ChooseTariffModal></ChooseTariffModal>
        <mainbox></mainbox>
        <section>
            <div class="container">
                <div class="wrapper">

                    <h1 class="title">{{ translation.translate('articles', 'title') }}</h1>
                    <div class="content" v-for="i in 5" :key="i">
                        <div class="content-header">
                            <span class="logo-small">
                                <img src="../assets/images/svg/edo2.svg" />

                            </span>
                        </div>
                        <div>
                            <h5 class="content-title">
                                <router-link :to="{ name: `article${i}` }" class="content-title">
                                    {{ translation.translate('articles', `title${i}`) }}
                                </router-link>
                            </h5>
                            <span> 
                                <router-link :to="{ name: `article${i}` }" class="description-mobile">
                                    {{ translation.translate('articles', `details`) }}
                                </router-link>
                            </span>
                            <span class="description">{{ translation.translate('articles', `description${i}`) }} </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <mainfooter></mainfooter>
    </div>
</template>

<script>
import translate from '../../translation/translate'
import mainbox from "./mainbox.vue";
import mainheader from "./mainheader.vue";
import mainfooter from "./mainfooter.vue";
import ChooseTariffModal from './modal/ChooseTariffModal.vue';

export default {
    name: 'articles',
    data() {
        return {
            translation: translate
        }
    },
    components: {
    mainheader,
    mainbox,
    mainfooter,
    ChooseTariffModal
},
    metaInfo() {
    return {
      title: "Статьи СЭД Faktura.EDO",
      meta: [
        {
          name: "description",
          content:
            "",
        },
      ],
    };
  },
}
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
}

.wrapper {
    width: 70%;
}

.title {
    /* font-family: 'Roboto'; */
    font-family: Lab Grotesque,-apple-system,BlinkMacSystemFont,Arial,Liberation Sans,Nimbus Sans L,sans-serif;
    font-weight: 500;
    font-size: 30px;
    line-height: 35.16px;
}

.content {
    margin-top: 10px;
    padding: 30px;
    position: relative;
}

.content::after {
    content: "";
    display: block;
    width: 95%;
    height: 1px;
    background-color: #C4C4C4;
    font-size: 15px;
    position: absolute;
    bottom: 0;
}
.content-title:hover {
    color: #62CB31;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}
.content-header {
    display: flex;
    height: 25px;
    /* font-family: 'Roboto'; */
    font-family: Lab Grotesque,-apple-system,BlinkMacSystemFont,Arial,Liberation Sans,Nimbus Sans L,sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.41px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #9B9B9B;
}

.logo-small {
    height: 16px;
}

.description-mobile {
    display: none;
    font-size: 19px;
    font-weight: 500;
    line-height: 22.27px;
    color: #62CB31;
}
.content-title {
    /* font-family: 'Roboto'; */
    font-family: Lab Grotesque,-apple-system,BlinkMacSystemFont,Arial,Liberation Sans,Nimbus Sans L,sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 22.78px;
    color: #5B5B5B;
}

.description {
    font-size: 19px;
    font-weight: 500;
    line-height: 22.27px;
    color: #676767;
}

@media (max-width: 767px) {
    .wrapper {
        width: 100%;
    }
    .content {
        padding-left: 25px;
        padding-right: 25px;
    }
    .content::after {
    width: 85%;
}
    .description {
        display: none;
    }
    .description-mobile {
        display: block;
    }
}
</style>